import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function AboutUs() {
  return (
    <Container
      id="about-us"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        About Us
      </Typography>
      <Typography component="p" variant="subtitle1" fontSize="1.2rem" color="text.secondary">
        Created by a team of neuroscientists, medical biophysicists and engineers who were tired of feeling tired. There was only so much caffeine and energy drinks could do. We set out to create a wearable device to help people deal with  issues  caused by lack of deep sleep.  Everything from depression, aging, loss of metabolism and even lack of libido are caused by poor sleep. We are proud to share our creation and help millions to improve their quality of life.
      </Typography>
    </Container>
  );
}
